<template>
  <!--register 註冊頁面內容-->
  <div class="flex flex-col justify-between h-full md:p-4 lg:px-12 md:shadow-pink md:rounded-xl">
    <!---->
    <a-modal centered :closable="false" :maskClosable="false" v-model="modals.ask.isShow">
      <template slot="title"><div class="text-center">請問您是否為可購樂會員</div></template>
      <img class="custom-modal-img-logo" src="@/assets/img/LOGO.png" />
      <div class="flex flex-col text-center">
        <button class="btn-forms w-full" @click="handleNotMemberMsgRouter(1)">我已是可購樂會員</button>
        <br />
        <button class="btn-forms w-full" @click="handleNotMemberMsgRouter(2)">我不是可購樂會員</button>
      </div>
      <template slot="footer"><div></div></template>
    </a-modal>

    <section class="forms-basic">
      <a-form-model ref="registerForm" :model="form" :rules="rules" class="grid md:grid-cols-2">
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>姓名</span><span class="must">*</span></p>
          <a-form-model-item prop="realname">
            <a-input type="text" placeholder="(必填)請輸入你的姓名" v-model="form.realname" />
          </a-form-model-item>
        </div>
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>手機</span><span class="must">*</span></p>
          <a-form-model-item prop="mobile">
            <a-input type="text" placeholder="(必填)請輸入你的手機" :maxLength="10" v-model="form.mobile" autocomplete="nope" />
          </a-form-model-item>
        </div>
        <div v-if="isVerifying" class="lg:mx-12 md:m-4">
          <p class="item-title"><span>檢核碼</span><span class="must">*</span><span class="ml-4 text-sm opacity-50">檢核碼已送出,請查看簡訊</span></p>
          <a-form-model-item prop="varify" ref="varify">
            <div class="relative">
              <a-input type="text" :maxLength="10" v-model="form.verify" placeholder="(必填)請輸入檢核碼" autocomplete="nope" />
              <button class="custom-mobile-btn" :disabled="countDownLocked" @click="handleGetSMS">
                <vac :left-time="60000" ref="vacCountDown">
                  <span slot="stopped">重發驗證碼</span>
                  <span slot="process" slot-scope="{ timeObj }"> {{ timeObj.ceil.s }} 秒等待 {{ getCountDownSec(timeObj.ceil.s) }} </span>
                  <span slot="finish">重發驗證碼</span>
                </vac>
              </button>
            </div>
          </a-form-model-item>
        </div>
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>新密碼</span><span class="must">*</span></p>
          <a-form-model-item prop="password">
            <a-input type="password" placeholder="(必填)密碼須為6字以上英數字" v-model="form.password" autocomplete="new-password" />
          </a-form-model-item>
        </div>
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>確認密碼</span><span class="must">*</span></p>
          <a-form-model-item prop="confirmPassword">
            <a-input type="password" placeholder="(必填)密碼須為6字以上英數字" v-model="form.confirmPassword" autocomplete="new-password" />
          </a-form-model-item>
        </div>
        <!-- <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>身分證號</span><span class="must">*</span></p>
          <a-form-model-item prop="pid">
            <a-input type="text" placeholder="請輸入你的身分證號" v-model="form.pid" />
          </a-form-model-item>
        </div> -->
        <!-- <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>身分證影本正面</span><span class="must">*</span></p>
          <a-form-model-item prop="idcard1">
            <Upload title="請上傳身分證影本正面" name="idcard1" :value="form.idcard1.value" @onChange="value => handleChange('idcard1', value)" />
          </a-form-model-item>
        </div> -->
        <!-- <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>身分證影本反面</span><span class="must">*</span></p>
          <a-form-model-item prop="idcard2">
            <Upload title="請上傳身分證影本反面" name="idcard2" :value="form.idcard2.value" @onChange="value => handleChange('idcard2', value)" />
          </a-form-model-item>
        </div> -->
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>(選填)存摺影本</span></p>
          <a-form-model-item prop="passbook">
            <Upload title="請上傳存摺影本" name="passbook" :value="form.passbook.value" @onChange="value => handleChange('passbook', value)" />
          </a-form-model-item>
        </div>
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>(必填)請提供社團 LINE 群組截圖、臉書粉專截圖</span><span class="must">*</span></p>
          <a-form-model-item prop="linegroup">
            <Upload title="請上傳群組截圖" name="linegroup" :value="form.linegroup.value" @onChange="value => handleChange('linegroup', value)" />
          </a-form-model-item>
        </div>
        <div class="lg:mx-12 md:m-4">
          <p class="item-title"><span>邀請碼</span></p>
          <a-form-model-item>
            <a-input type="text" placeholder="(非必填)請輸入你的邀請碼" v-model="form.invitationCode" />
          </a-form-model-item>
        </div>
      </a-form-model>
    </section>
    <section class="py-6">
      <button class="btn-forms w-full md:w-auto btn-forms md:py-2 md:px-24 block mx-auto" @click="handleSubmit">確認註冊</button>
    </section>
  </div>
</template>

<script>
import Upload from '@/components/Upload.vue'
import { mapActions, mapGetters } from 'vuex'
import { regExpPassword, regExpMobile } from '@/utils/regExp.js'


export default {
  props: {
    dataSource: {
      type: Object
    },
    type: {
      type: String,
      default: 'register'
    }
  },
  components: {
    Upload,
  },
  data() {
    return {
      form: {
        realname: '',
        mobile: '',
        verify: '',
        password: '',
        confirmPassword: '',
        // pid: '',
        invitationCode: '',
        // idcard1: {},
        // idcard2: {},
        passbook: {},
        linegroup: {}
      },
      rules: {
        realname: { required: true, message: '請輸入姓名', trigger: 'change' },
        mobile: { required: true, validator: (rule, value, callback) => this.validatorVerify(value, callback, '手機號碼輸入格式為 0987654321'), trigger: 'change' },
        verify: { required: true, message: '請輸入手機檢核碼', trigger: 'change' },
        password: { required: true, pattern: regExpPassword, message: '密碼6-20字, 英文大小寫數字至少1個', trigger: 'change' },
        confirmPassword: { required: true, validator: (rule, value, callback) => this.validatorComparePassword(value, callback, '兩者密碼不相同'), trigger: 'change' },
        // pid: { required: true, len: 10, message: '請輸入身分證字號10碼', trigger: 'change' },
        // idcard1: { required: true, validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳身分證影本正面'), trigger: 'change' },
        // idcard2: { required: true, validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳身分證影本反面'), trigger: 'change' },
        passbook: { required: false, trigger: 'change' },
        linegroup: { required: true, validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳群組截圖'), trigger: 'change' }
      },
      modals: {
        ask: {
          isShow: false
        }
      },
      isVerifying: '', //手機有沒有認證並滿10數字
      countDownSec: '',
      countDownLocked: true
    }
  },
  methods: {
    ...mapActions(['getVerifySMS', 'register', 'setMobileExists']),
    // async recaptcha() { 
    //   await this.$recaptchaLoaded()
    //   const token = await this.$recaptcha('register')
    //   return token
    // },
    getCountDownSec(sec) {
      //套件倒數的秒數
      this.countDownSec = sec
    },
    handleChange(column, { file, value }) {
      this.form[column] = {
        file,
        value
      }

      this.$refs.registerForm.validateField(column)
    },
    handleGetSMS() {
      // * api 取手機簡訊
      let formData = new FormData()
      let value = this.form.mobile
      console.log(value)
      formData.append('mobile', value)
      formData.append('check_exist', true)
      this.$nextTick(function() {
        this.$refs.vacCountDown.startCountdown(true)
      })
      this.getVerifySMS(formData) //取得手機驗證碼
    },
    handleSubmit() {
      console.log(this.$refs.registerForm)
      console.log(this.$refs.registerForm.validate)
      this.$refs.registerForm.validate(valid => {
        if (!valid){
          let errorEl = this.$refs.registerForm.fields.filter(x => x.validateState == 'error')
          if(errorEl) {
            errorEl[0].$el.previousElementSibling.scrollIntoView()
            window.scroll(0, window.scrollY - 50)
          }
          return
        }
        //const token = this.recaptcha()
        this.handleRegister()
      })
    },
    validatorComparePassword(value, callback, errorText) {
      const password = this.form.password
      if (value != password) {
        callback(errorText)
      }
      callback()
    },
    validatorVerify(value, callback, errorText) {
      const reg = regExpMobile
      if (!reg.test(value)) {
        this.isVerifying = false
        callback(errorText)
      } else if (reg.test(value)) this.isVerifying = true
      callback()
    },
    validatorPic(value, callback, errorText) {
      if (!value.value) {
        callback(errorText)
      }
      callback()
    },
    async handleRegister() {
      const formData = new FormData()
      formData.append('realname', this.form.realname)
      formData.append('mobile', this.form.mobile)
      formData.append('verify', this.form.verify)
      formData.append('password', this.form.password)
      // formData.append('pid', this.form.pid)
      formData.append('code', this.form.invitationCode) //邀請碼
      // formData.append('idcard1', this.form.idcard1.file)
      // formData.append('idcard2', this.form.idcard2.file)
      formData.append('passbook', this.form.passbook.file)
      formData.append('linegroup', this.form.linegroup.file)
      ;(await this.register(formData)) || {}
    },
    handleNotMemberMsgRouter(type) {
      this.modals.ask.isShow = false
      this.setMobileExists(false)
      if (type == 1) {
        this.$router.push({ name:"login", query:{mobile: this.form.mobile,desc:1} })
      }
      if (type == 2) {
        this.$router.push({ name:"errorPage", query:{mobile: this.form.mobile,desc:1,title:'帳號異常'} })
      }
    }
  },
  mounted() {
    if (this.dataSource) {
      this.form = { ...this.dataSource }
    }
  },
  computed: {
    ...mapGetters(['isMobileExists'])
  },
  watch: {
    countDownSec: {
      handler(value) {
        if (value === 1) {
          setTimeout(() => {
            this.countDownLocked = false
          }, 1000)
        } else this.countDownLocked = true
      }
    },
    isVerifying: {
      handler(value) {
        if (value == true) {
          this.handleGetSMS()
        }
      }
    },
    isMobileExists: {
      immediate: true,
      handler(value) {
        this.modals.ask.isShow = value
      }
    },
    'modals.ask.isShow': {
      immediate: true,
      handler(value) {
        if (value == false) this.setMobileExists(false)
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
.custom-mobile-btn {
  @apply absolute right-0 top-0 rounded-2xl px-4;
  background: #fff;
  border: #513e41 1px solid;
  color: #513e41;
  height: 36px;
  line-height: 1;
}
.custom-mobile-btn:disabled {
  background: #e0e0e0;
  border: 0;
}
.custom-circle {
  padding: 0;
  width: 44px;
  height: 40px;
}
.custom-modal-img-logo{
    width: 180px;
    margin: 0 auto 2rem;
}
</style>
