<template>
  <div>
    <input type="file" :id="name" class="upload" @change="handleChange">
      <label :for="name" v-if="!value">
          <section class="item-con inp-upload">
              <figure>
                  <img src="../assets/img/icon_camera.svg" class="mb-4">
                  <figcaption >選擇照片</figcaption>
              </figure>
              <div class="m-0 leading-none mb-2">{{title}}</div>
              <div class="m-0 leading-none text-gray-400">圖片大小限制為10MB</div>
          </section>
      </label>
      <p class="item-con inp-again" v-else>
          <img :src="value" alt="" />
          <button class="btn-basic">
              <label :for="name">重新上傳</label>
          </button>
      </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      require: true,
      default: '',
    },
    title: {
      type: String,
      require: true,
      default: '',
    },
    value: {
      type: String,
    }
  },
  methods: {
    handleChange(event) {
      const file = event.target.files[0]
      if (!file) return
      const reader = new FileReader()
      const that = this
      reader.readAsDataURL(file)
      reader.onload = function() {
          that.$emit('onChange', {
            file,
            value: this.result
          })
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
  p.inp-again {
    justify-content: space-between;
      img {
          width: calc(100% - 100px);
      }
  }
  .inp-upload{
    padding:20px 10px;
  }
  .inp-upload:before{
    @apply p-4
  }
</style>