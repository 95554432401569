<template>
  <AuthContainer @onBack="handleBack">
    <AuthBackContainer>
      <RegisterForm @onBack="handleBack"/>
    </AuthBackContainer>
  </AuthContainer>
</template>

<script>
import AuthContainer from '@/components/auth/AuthContainer'
import RegisterForm from '@/components/RegisterForm'
import AuthBackContainer from '@/components/auth/AuthBackContainer'

export default {
  components: {
    AuthContainer,
    AuthBackContainer,
    RegisterForm
  },
  methods: {
    handleBack() {
      this.$router.push('/')
    },
  }
}
</script>
