
// Email驗證
export const regExpEmail = new RegExp(
  //對方提供
  /^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/

);

// 使用者名稱
export const regUserNamer = new RegExp(
//對方提供
  /^([\u4e00-\u9fa5]+|([a-zA-Z]+\s?)+)$/
)

// Password驗證
export const regExpPassword = new RegExp(
  //對方提供
  /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9~!@#$%^&*_+-`?=,.\\/|]{6,20}$/
)


// 手機驗證
export const regExpMobile =  new RegExp(/^09\d{8}$/)

//COCO幣驗證
export const regCoco = new RegExp(/^(0|[1-9][0-9]*)$/)