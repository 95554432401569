import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=0b0e2926&scoped=true&"
import script from "./RegisterForm.vue?vue&type=script&lang=js&"
export * from "./RegisterForm.vue?vue&type=script&lang=js&"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=0b0e2926&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0e2926",
  null
  
)

export default component.exports